import { streamFetch } from '@/lib/request'
import { OptionsCallback } from './chat'

const urlPrefix = '/newApi'

//用户和财产信息
export const loadDashboardApi = (id: string, signal?: AbortSignal) => {
  return new Promise((resolve, reject) => {
    fetch(`${urlPrefix}/client/client_investment/${id}`, {
      method: 'GET',
      credentials: 'include',
      signal: signal // 将signal传递给fetch
    })
      .then(response => {
        if (!response.ok) {
          // 如果响应状态码不在200-299之间， rejected
          return reject(response);
        }
        return response.json(); // 解析为json
      })
      .then(data => resolve(data)) // 成功解析后resolve
      .catch(error => {
        // 处理fetch请求错误和请求被中断的情况
        if (error.name === 'AbortError') {
          console.log('请求已被中断');
        } else {
          reject(error);
        }
      });
  });
}

export const loadWMInfoApi = (id: string, signal?: AbortSignal) => {
  return new Promise((resolve, reject) => {
    fetch(`${urlPrefix}/user/user/${id}`, {
      method: 'GET',
      credentials: 'include',
      signal: signal // 将signal传递给fetch
    })
      .then(response => {
        if (!response.ok) {
          // 如果响应状态码不在200-299之间， rejected
          return reject(response);
        }
        return response.json(); // 解析为json
      })
      .then(data => resolve(data)) // 成功解析后resolve
      .catch(error => {
        // 处理fetch请求错误和请求被中断的情况
        if (error.name === 'AbortError') {
          console.log('请求已被中断');
        } else {
          reject(error);
        }
      });
  });
}

export const loadUserListApi = (id: string) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open('GET', urlPrefix + '/client/client_info?primary_relationship_manager=' + id)
    xhr.withCredentials = true
    xhr.responseType = 'json'
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if (xhr.status === 200)
          resolve(xhr.response)
        else
          reject(xhr)
      }
    }
    xhr.send()
  })
}


export const loadUserInvestmentGainApi = (params: any, signal?: AbortSignal) => {
  return new Promise((resolve, reject) => {
    fetch(`${urlPrefix}/client/client_investment_gain`, {
      method: 'POST',
      signal: signal, // 将signal传递给fetch
      headers: {
        'Content-Type': 'application/json' // 指定请求头为 JSON
      },
      body: JSON.stringify(params)
    })
      .then(response => {
        if (!response.ok) {
          // 如果响应状态码不在200-299之间， rejected
          return reject(response);
        }
        return response.json(); // 解析为json
      })
      .then(data => resolve(data)) // 成功解析后resolve
      .catch(error => {
        // 处理fetch请求错误和请求被中断的情况
        if (error.name === 'AbortError') {
          console.log('请求已被中断');
        } else {
          reject(error);
        }
      });
  });
}

export const loadUsersDetailApi = (params: any, signal?: AbortSignal) => {
  return new Promise((resolve, reject) => {
    fetch(`${urlPrefix}/client/clients_investments`, {
      method: 'POST',
      signal: signal, // 将signal传递给fetch
      headers: {
        'Content-Type': 'application/json' // 指定请求头为 JSON
      },
      body: JSON.stringify(params)
    })
      .then(response => {
        if (!response.ok) {
          // 如果响应状态码不在200-299之间， rejected
          return reject(response);
        }
        return response.json(); // 解析为json
      })
      .then(data => resolve(data)) // 成功解析后resolve
      .catch(error => {
        // 处理fetch请求错误和请求被中断的情况
        if (error.name === 'AbortError') {
          console.log('请求已被中断');
        } else {
          reject(error);
        }
      });
  });
}


export const loadPortfolioTrendDataApi = (id: string, start_date: string, end_date: string, signal?: AbortSignal) => {
  return new Promise((resolve, reject) => {
    // 构造查询参数
    const queryParams = new URLSearchParams({
      start_date,
      end_date
    }).toString();

    fetch(`${urlPrefix}/client/client_investment_history/${id}?${queryParams}`, {
      method: 'GET',
      credentials: 'include',
      signal: signal // 将signal传递给fetch
    })
      .then(response => {
        if (!response.ok) {
          // 如果响应状态码不在200-299之间， rejected
          return reject(response);
        }
        return response.json(); // 解析为json
      })
      .then(data => resolve(data)) // 成功解析后resolve
      .catch(error => {
        // 处理fetch请求错误和请求被中断的情况
        if (error.name === 'AbortError') {
          console.log('请求已被中断');
        } else {
          reject(error);
        }
      });
  });
}



export const updateClientInfoApi = (params: any, signal?: AbortSignal) => {
  return new Promise((resolve, reject) => {
    fetch(`${urlPrefix}/client/updateClientPortfolio`, {
      method: 'PUT',
      signal: signal, // 将signal传递给fetch
      headers: {
        'Content-Type': 'application/json' // 指定请求头为 JSON
      },
      body: JSON.stringify(params)
    })
      .then(response => {
        if (!response.ok) {
          // 如果响应状态码不在200-299之间， rejected
          return reject(response);
        }
        return response.json(); // 解析为json
      })
      .then(data => resolve(data)) // 成功解析后resolve
      .catch(error => {
        // 处理fetch请求错误和请求被中断的情况
        if (error.name === 'AbortError') {
          console.log('请求已被中断');
        } else {
          reject(error);
        }
      });
  });
}

export const updateWMInfoApi = (id: any,params: any, signal?: AbortSignal) => {
  return new Promise((resolve, reject) => {
    fetch(`${urlPrefix}/user/user/${id}`, {
      method: 'PUT',
      signal: signal, // 将signal传递给fetch
      headers: {
        'Content-Type': 'application/json' // 指定请求头为 JSON
      },
      body: JSON.stringify(params)
    })
      .then(response => {
        if (!response.ok) {
          // 如果响应状态码不在200-299之间， rejected
          return reject(response);
        }
        return response.json(); // 解析为json
      })
      .then(data => resolve(data)) // 成功解析后resolve
      .catch(error => {
        // 处理fetch请求错误和请求被中断的情况
        if (error.name === 'AbortError') {
          console.log('请求已被中断');
        } else {
          reject(error);
        }
      });
  });
}

export const deleteUserApi = (id: string, signal?: AbortSignal) => {
  return new Promise((resolve, reject) => {
    fetch(`${urlPrefix}/client/client/${id}`, {
      method: 'DELETE',
      signal: signal, // 将signal传递给fetch
      headers: {
        'Content-Type': 'application/json' // 指定请求头为 JSON
      }
    })
      .then(response => {
        if (!response.ok) {
          // 如果响应状态码不在200-299之间， rejected
          return reject(response);
        }
        return response.json(); // 解析为json
      })
      .then(data => resolve(data)) // 成功解析后resolve
      .catch(error => {
        // 处理fetch请求错误和请求被中断的情况
        if (error.name === 'AbortError') {
          console.log('请求已被中断');
        } else {
          reject(error);
        }
      });
  });
}

// 获取当前客户的相似客户
const baseUrl = process.env.NEXT_PUBLIC_AGENT_URL
export const getSimilarityUsers = (params: {
  target_client_id: string,
  user?: string
}, signal?: AbortSignal) => {
  return new Promise((resolve, reject) => {
    fetch(`${baseUrl}/v1/workflows/run`, {
      method: 'POST',
      signal: signal,
      headers: {
        'Authorization': `Bearer ${process.env.NEXT_PUBLIC_AGENT_KEY2}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        inputs: {
          target_client_id: params.target_client_id
        },
        response_mode: "blocking",
        user: params.user || "wealth-manage-default"
      })
    })
      .then(response => {
        if (!response.ok) {
          return reject(response);
        }
        return response.json();
      })
      .then(data => resolve(data))
      .catch(error => {
        if (error.name === 'AbortError') {
          console.log('请求已被中断');
        } else {
          reject(error);
        }
      });
  });
}

// 获取当前客户和相似客户的具体差异
export const compareClientApi = (params: {
  client_id_a: string,
  client_id_b: string,
  user?: string
}, optionsCallback: OptionsCallback) => {
  return streamFetch(
    baseUrl + '/v1/workflows/run',
    {
      "inputs": {
        "client_id_a": params.client_id_a,
        "client_id_b": params.client_id_b,
      },
      "response_mode": "streaming",
      "user": params.user || "wealth-manage-default",
    },
    optionsCallback,
    {
      Authorization: `Bearer ${process.env.NEXT_PUBLIC_AGENT_KEY3}`
    }
  )
}


export const compareClientApi2 = (params: {
  client_id_a: string,
  client_id_b: string,
  user?: string
}, signal?: AbortSignal) => {
  return new Promise((resolve, reject) => {
    fetch(`${baseUrl}/v1/workflows/run`, {
      method: 'POST',
      signal: signal,
      headers: {
        'Authorization': `Bearer ${process.env.NEXT_PUBLIC_AGENT_KEY3}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        inputs: {
          "client_id_a": params.client_id_a,
          "client_id_b": params.client_id_b,
        },
        response_mode: "blocking",
        user: params.user || "wealth-manage-default"
      })
    })
      .then(response => {
        if (!response.ok) {
          return reject(response);
        }
        return response.json();
      })
      .then(data => resolve(data))
      .catch(error => {
        if (error.name === 'AbortError') {
          console.log('请求已被中断');
        } else {
          reject(error);
        }
      });
  });
}
