import { apiClient } from './base';


// 获取邮件策略列表
export const getPlanListApi = async () => {
  return new Promise((resolve, reject) => {
    apiClient.get('/email/email-strategies')
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
  });
};
// 获取单个邮件策略详情
export const getPlanDetailsApi = async (planId: string) => {
  return new Promise((resolve, reject) => {
    apiClient.get(`/email/email-strategies/${planId}`)
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

// 获取邮件详情
export const getEmailDetailsApi = async (recipientId: string) => {
  return new Promise((resolve, reject) => {
    apiClient.get(`/email/email-details`, {
      params: { recipient_id: recipientId }
    })
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

// 更新邮件详情
export interface UpdateEmailParams {
  email_content: string;
  recipient_address: string;
  email_title: string;
  send_status: string;
}

export const updateEmailDetailsApi = (emailId: string, params: UpdateEmailParams) => {
  return new Promise((resolve, reject) => {
    apiClient.put(`/email/email-details/${emailId}`, params)
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const sendEmailApi = async (emailId: string) => {
  return new Promise((resolve, reject) => {
    apiClient.post(`/email/email-details/send/${emailId}`)
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

// 获取邮件配置
export const getEmailConfigsApi = async () => {
  return new Promise((resolve, reject) => {
    apiClient.get('/email/email-configs')
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

// 新增邮件配置
export interface CreateEmailConfigParams {
  email_account: string;
  email_server: string;
  email_password: string;
}

export const createEmailConfigApi = async (params: CreateEmailConfigParams) => {
  return new Promise((resolve, reject) => {
    apiClient.post('/email/email-configs', params, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

// 更新邮件配置
export interface UpdateEmailConfigParams {
  email_account: string;
  email_server: string;
  email_password: string;
}

export const updateEmailConfigsApi = async (id: string, params: UpdateEmailConfigParams) => {
  return new Promise((resolve, reject) => {
    apiClient.put(`/email/email-configs/${id}`, params, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

// 删除邮件配置
export const deleteEmailConfigApi = async (id: string) => {
  return new Promise((resolve, reject) => {
    apiClient.delete(`/email/email-configs/${id}`)
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
  });
};


// 从分组中移除成员
export const removeGroupMemberApi = async (groupId: string, memberId: string) => {
  return new Promise((resolve, reject) => {
    apiClient.delete(`/email/groups/${groupId}/members/${memberId}`)
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

// 创建计划
export interface CreateEmailStrategyParams {
  email_header: string;
  email_footer: string;
  send_schedule: string[];
  requires_confirmation: boolean;
  email_config_id: number;
  title: string;
  email_content_id: number;
}

export const createEmailStrategyApi = async (params: CreateEmailStrategyParams) => {
  return new Promise((resolve, reject) => {
    apiClient.post('/email/email-strategies', params, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

// 获取所有接收者分组
export const getAllGroupsApi = async () => {
  return new Promise((resolve, reject) => {
    apiClient.get('/email/groups')
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
  });
};
export const getGroupDetailsApi = async (groupId: string) => {
  return new Promise((resolve, reject) => {
    apiClient.get(`/email/groups/${groupId}`)
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
  });
};
// 创建接收者分组
export interface CreateGroupParams {
  group_name: string;
  group_description: string;
}

export const createGroupApi = async (params: CreateGroupParams) => {
  return new Promise((resolve, reject) => {
    apiClient.post('/email/groups', params, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
  });
};
// 添加分组成员
export interface AddGroupMembersParams {
  person_ids: string[];
}

export const addGroupMembersApi = (groupId: string, params: AddGroupMembersParams) => {
  return new Promise((resolve, reject) => {
    apiClient.post(`/email/groups/${groupId}/members`, params)
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

// 获取邮件内容列表
export const getEmailContentsApi = async () => {
  return new Promise((resolve, reject) => {
    apiClient.get('/email/email-contents')
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
  });
};