'use client'

export * from './chat'
export * from './user'
export * from './plan'


const urlPrefix = '/newApi'


export const uploadApi = (options: any, isPublicAPI?: boolean, url?: string, searchParams?: string): Promise<any> => {
  let token = ''
  if (isPublicAPI) {
    const sharedToken = globalThis.location.pathname.split('/').slice(-1)[0]
    const accessToken = localStorage.getItem('token') || JSON.stringify({ [sharedToken]: '' })
    let accessTokenJson = { [sharedToken]: '' }
    try {
      accessTokenJson = JSON.parse(accessToken)
    }
    catch (e) {

    }
    token = accessTokenJson[sharedToken]
  }
  else {
    const accessToken = localStorage.getItem('console_token') || ''
    token = accessToken
  }
  const defaultOptions = {
    method: 'POST',
    url: (url ? `${urlPrefix}${url}` : `${urlPrefix}/client/import_investment_info/${options.data.get('manager_id')}`) + (searchParams || ''),
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {},
  }
  options = {
    ...defaultOptions,
    ...options,
    headers: { ...defaultOptions.headers, ...options.headers },
  }
  return new Promise((resolve, reject) => {
    const xhr = options.xhr
    xhr.open(options.method, options.url)
    for (const key in options.headers)
      xhr.setRequestHeader(key, options.headers[key])

    xhr.withCredentials = true
    xhr.responseType = 'json'
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if (xhr.status === 201 || xhr.status === 200) {
          console.log('1233', xhr);
          resolve(xhr.response)
        } else {
          reject(xhr)
        }
      }
    }
    xhr.upload.onprogress = options.onprogress
    xhr.send(options.data)
  })
}

export const uploadUserAvatarApi = (file: File, id: string): Promise<any> => {
  const formData = new FormData();
  formData.append('file', file); // 将文件添加到 FormData 中

  const options = {
    method: 'POST',
    url: urlPrefix + `/file/upload?id=${id}`,
    headers: {
      'accept': 'application/json',
    },
    data: formData,
  };

  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open(options.method, options.url);
    for (const key in options.headers) {
      xhr.setRequestHeader(key, options.headers[key]);
    }

    xhr.withCredentials = true;
    xhr.responseType = 'json';
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if (xhr.status === 201 || xhr.status === 200) {
          resolve(xhr.response);
        } else {
          reject(xhr);
        }
      }
    };
    xhr.send(options.data);
  });
};

export const loadFileListApi = (userId: any) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open('GET', urlPrefix + '/client/import_investment_info/'+userId)
    xhr.withCredentials = true
    xhr.responseType = 'json'
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if (xhr.status === 200)
          resolve(xhr.response)
        else
          reject(xhr)
      }
    }
    xhr.send()
  })
}


export const loginApi = (username: string, password: string) => {
  console.log('232333');

  const url = `${urlPrefix}/user/login?username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`;
  return fetch(url, {
    method: 'GET',
    credentials: 'include', // 确保包含凭据
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json(); // 解析 JSON 响应
    })
    .catch(error => {
      throw error; // 处理错误
    });
}

export const sendCodeApi = (email: string) => {
  return (new Promise((resolve, reject) => {
    try {
      fetch(urlPrefix + '/user/send-code', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json' // 指定请求头为 JSON
        },
        body: JSON.stringify({
          email: email
        })
      }).then(response => {
        if (!response.ok) {
          // 如果响应状态码不在200-299之间， rejected
          return reject(response);
        }
        return response.json(); // 解析为json
      }).then(data => {
        resolve(data)
      });
    } catch (error) {
      console.error('Error uploading file:', error);
      reject(error)
    }
  })) as Promise<{ text: string }>
}


//增加个人用户
export const register1Api = (params: any) => {
  return new Promise((resolve, reject) => {
    try {
      fetch(urlPrefix + '/client/client-user', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json' // 指定请求头为 JSON
        },
        body: JSON.stringify(params)
      }).then(response => {
        // 尝试解析响应体为 JSON
        return response.json().then(data => {
          return resolve({ status: response.status, data });
        });
      }).catch(error => {
        console.error('Error parsing response:', error);
        reject(error);
      });
    } catch (error) {
      console.error('Error uploading file:', error);
      reject(error);
    }
  }) as Promise<{ status: number, data: any }>;
};

//增加经理用户
export const register0Api = (params: any) => {
  return new Promise((resolve, reject) => {
    try {
      fetch(urlPrefix + '/user/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json' // 指定请求头为 JSON
        },
        body: JSON.stringify(params)
      }).then(response => {
        // 尝试解析响应体为 JSON
        return response.json().then(data => {
          return resolve({ status: response.status, data });
        });
      }).catch(error => {
        console.error('Error parsing response:', error);
        reject(error);
      });
    } catch (error) {
      console.error('Error uploading file:', error);
      reject(error);
    }
  }) as Promise<{ status: number, data: any }>;
};


export const htmlToPdfApi = (htmlContent: string) => {
  return new Promise((resolve, reject) => {
    try {
      fetch(urlPrefix + '/chat/html-to-pdf', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.NEXT_PUBLIC_AGENT_KEY}`,
        },
        body: htmlContent
      }).then(response => {
        if (!response.ok) {
          throw response;
        }
        return response.blob();
      }).then(blob => {
        resolve(blob);
      }).catch(error => {
        reject(error);
      });
    } catch (error) {
      console.error('Error converting HTML to PDF:', error);
      reject(error);
    }
  }) as Promise<Blob>;
}
