import axios from 'axios';

// 后端的接口
const apiClient = axios.create({
  baseURL: '/newApi', // 替换为你的 API 基础 URL
  timeout: 10000000, // 请求超时设置
});

// 添加请求拦截器
apiClient.interceptors.request.use(
  config => {
    // 在请求头中添加统一的 header 配置
    if (!config.url.includes('/api/resume/upload_resume')) {
      config.headers['Content-Type'] = 'application/json';
    }
    
    // 假设你有一个函数获取 token
    const token = localStorage.getItem('access_token'); // 从本地存储获取 token
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`; // 添加 token
    }
    
    return config;
  },
  error => {
    // 处理请求错误
    return Promise.reject(error);
  }
);

// 添加响应拦截器
apiClient.interceptors.response.use(
  response => {
    // 处理响应数据
    return response.data; // 直接返回数据
  },
  error => {
    if (error?.status === 403 || error?.status === 401) {
      window.location.href = '/login'
      return;
    }
    // 处理响应错误
    if (error.response) {
      // 服务器响应状态码不在 2xx 范围内
      console.error('Response error:', error.response);
    } else {
      // 其他错误
      console.error('Error:', error.message);
    }
    return Promise.reject(error);
  }
);

// nextjs写的接口
const apiServer = axios.create({
  baseURL: '/api', // 新的 API 基础 URL
  timeout: 10000000, // 请求超时设置
});
apiServer.interceptors.response.use(
  response => {
    // 处理响应数据
    return response.data; // 直接返回数据
  },
  error => {
    if (error?.status === 403 || error?.status === 401) {
      window.location.href = '/login'
      return;
    }
    // 处理响应错误
    if (error.response) {
      // 服务器响应状态码不在 2xx 范围内
      console.error('Response error:', error.response);
    } else {
      // 其他错误
      console.error('Error:', error.message);
    }
    return Promise.reject(error);
  }
);
// 导出 API 客户端
export { apiClient, apiServer };