function getDefaultHeaders(): Record<string, string> {
  return {};
}

interface OptionsCallback {
  onbegin?: (response: Response) => void;
  onprogress?: (data: string, abortFetch: () => void) => void;
  onsuccess?: () => void;
  onerror?: (error: any) => void;
}

export async function streamFetch(
  url: string,
  params: any,
  optionsCallback: OptionsCallback,
  userHeaders:object = {}
): Promise<void> {
  // const token = await getCheckAndReGenToken('service')
  const headers: Record<string, string> = {
    // Authorization: token,
    ...userHeaders,
    ...getDefaultHeaders()
  };
  
  if (!(params instanceof FormData)) {
    headers['Content-Type'] = 'application/json;charset=UTF-8';
  }

  const controller = new AbortController();
  const decoder = new TextDecoder();

  return fetch(url, {
    method: 'POST',
    headers,
    signal: controller.signal,
    body: params instanceof FormData ? params : JSON.stringify(params)
  }).then(async (response) => {
    if (!response.ok) {
      optionsCallback.onerror && optionsCallback.onerror(response);
      return;
    }
    
    const body = response.body;
    if (!body) {
      optionsCallback.onerror && optionsCallback.onerror(response);
      return;
    }
    
    optionsCallback.onbegin && optionsCallback.onbegin(response);
    const reader = body.getReader();
    
    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        optionsCallback.onsuccess && optionsCallback.onsuccess();
        break;
      }
      
      const string = decoder.decode(value);
      
      function abortFetch() {
        controller.abort();
      }
      
      optionsCallback.onprogress && optionsCallback.onprogress(string, abortFetch);
    }
  }).catch(err => {
    console.error('streamFetch err', err);
    optionsCallback.onerror && optionsCallback.onerror(err);
  });
}